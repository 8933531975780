<template>
  <div>
    <hedaer></hedaer>
    <div class="journalismInfo item">
      <div
        class="journalismInfo-title"
        v-for="(item, index) in obj.title"
        :key="index + 90"
      >
        {{ item }}
      </div>

      <div class="journalismInfo-date">{{ obj.date }}</div>
      <div class="journalismInfo-line"></div>
      <div v-for="(item, index) in obj.list" :key="index">
        <p class="journalismInfo-text" v-if="item.text">
          {{ item.text }}
        </p>
        <h5 v-else-if="item.tilte" class="item-title">
          {{ item.tilte }}
        </h5>
        <div v-else-if="item.smallTile" class="item-smallTile">
          {{ item.smallTile }}
        </div>
        <img :src="item.Imag" alt="" class="item-img" v-else />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import infoList from '@/utils/data'
export default {
  data () {
    return {
      obj: {
        title: '播种希望 筑梦未来 碧莲盛开展六一爱心助学公益行',
        date: '发布时间:2022-06-01',
        list: []
      }
    }
  },
  created () {
    if (infoList[this.$route.query.id]) {
      this.obj = infoList[this.$route.query.id]
    } else {
      this.obj = infoList[0]
    }
    document.documentElement.scrollTop = 0
  }
}
</script>

<style lang="scss">
.journalismInfo {
  .journalismInfo-title {
    text-align: center;
    font-size: 29px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 42px;
    letter-spacing: 0px;
    color: #363636;
  }
  .journalismInfo-date {
    margin-top: 18px;
    text-align: center;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #707070;
  }
  .journalismInfo-line {
    border-bottom: solid 1px #d4d3d3;
    margin-top: 35px;
    margin-bottom: 34px;
  }
  .journalismInfo-text {
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 33px;
    letter-spacing: 0px;
    color: #707070;
  }
  .item-title {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: #363636;
    margin-top: 20px;
  }
  .item-smallTile {
    margin-top: 20px;
    font-size: 18px;
    color: #363636;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 30px;
  }
  .item-img {
    width: 100%;
    margin: 20px 0;
  }
}
</style>
